import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "analytics flex" }
const _hoisted_2 = { class: "analytics__filter flex flex-column" }
const _hoisted_3 = { class: "analytics__graph flex" }
const _hoisted_4 = {
  class: "graph flex",
  style: {"flex":"1"}
}
const _hoisted_5 = { style: {"flex":"1"} }
const _hoisted_6 = {
  key: 1,
  class: "analytics__chart"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AnalyticsFilter = _resolveComponent("AnalyticsFilter")!
  const _component_NoData = _resolveComponent("NoData")!
  const _component_EChart = _resolveComponent("EChart")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AnalyticsFilter, {
        class: "flex-100",
        data: _ctx.data,
        "onUpdate:data": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data) = $event)),
        analytic: _ctx.analytic,
        "onUpdate:analytic": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.analytic) = $event)),
        bin: _ctx.bin,
        "onUpdate:bin": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.bin) = $event)),
        to: _ctx.to,
        "onUpdate:to": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.to) = $event)),
        from: _ctx.from,
        "onUpdate:from": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.from) = $event)),
        stations: _ctx.stations,
        "onUpdate:stations": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.stations) = $event)),
        procedures: _ctx.procedures,
        "onUpdate:procedures": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.procedures) = $event))
      }, null, 8, ["data", "analytic", "bin", "to", "from", "stations", "procedures"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_Transition, {
            name: "grow",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_ctx.empty)
                ? (_openBlock(), _createBlock(_component_NoData, { key: 0 }))
                : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_Card, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_EChart, {
                          class: "chart",
                          options: _ctx.chartOptionsBar,
                          style: {"height":"800px"}
                        }, null, 8, ["options"])
                      ]),
                      _: 1
                    })
                  ]))
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}