
import { defineComponent, PropType } from 'vue'

import { useVModel } from '@vueuse/core'

export default defineComponent({
  name: 'FancySelect',
  props: {
    options: {
      type: Object as PropType<{[k: string]: string}>,
      required: true,
    },
    selections: {
      type: Object as PropType<{[k: string]: boolean}>,
      required: true,
    },
  },
  emits: ['update:selections'],
  setup(props, { emit }) {
    const selectionsModel = useVModel(props, 'selections', emit)
    return { selectionsModel }
  },
})
