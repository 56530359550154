
import { computed, defineComponent, onBeforeMount, ref, reactive, watch, PropType } from 'vue'
import { storeToRefs } from 'pinia'
import { useUsersStore } from '@/stores/users/users'
import moment from 'moment'
import _ from 'lodash'
import debounce from 'lodash/debounce'

import useStationListModel from '@/models/list/stations'
import useProcedureListModel from '@/models/list/procedures'
import { api } from '@/main'

import FancySelect from '@/components/FancySelect.vue'
import { useVModels } from '@vueuse/core'

type BinValue = 'monthly' | 'weekly' | 'daily' | 'hourly'
type AnalyticValue = 'cycle-time' | 'production-output' | 'deviations'
type Data = [number, number][] | {x: string, y: number}[]
type AnalyticData = { name: string, data: Data, station?: string, procedure?: string }

export default defineComponent({
  name: 'AnalyticsFilter',
  components: {
    FancySelect,
  },
  props: {
    data: {
      type: Array as PropType<AnalyticData[]>,
      required: true,
    },
    bin: {
      type: String as PropType<BinValue>,
      required: true,
    },
    analytic: {
      type: String as PropType<AnalyticValue>,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    from: {
      type: String,
      required: true,
    },
    procedures: {
      type: Array as PropType<string[]>,
      required: true,
    },
    stations: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  emits: ['update:data', 'update:analytic', 'update:bin'],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setup(props, { emit }) {
    const usersStore = useUsersStore()
    const {
      userGroup,
    } = storeToRefs(usersStore)

    const {
      data,
      analytic,
      bin,
      to,
      from,
      stations: vStation,
      procedures: vProcedure,
    } = useVModels(props, emit)

    const bins = ref({
      monthly: 'Month',
      weekly: 'Week',
      daily: 'Day',
      hourly: 'Hour',
    })

    const analytics = ref({
      'production-output': 'Production Output',
      'cycle-time': 'Cycle Time',
      deviations: 'Deviations',
    })

    type Selection = { [k: string]: boolean }
    const filters = reactive({
      procedures: {} as Selection,
      stations: {} as Selection,
    })

    const procedureModel = useProcedureListModel()
    const stationModel = useStationListModel()

    const customerGroupsFilter = computed(() => {
      return JSON.stringify({
        $and: [
          { manufacturer: { $in: userGroup.value } },
        ],
      })
    })

    onBeforeMount(async () => {
      await Promise.all([
        procedureModel.fetchItems({ pageSize: -1, orderBy: 'friendlyName', filter: customerGroupsFilter.value }),
        stationModel.fetchItems({ pageSize: -1, orderBy: 'friendlyName', filter: customerGroupsFilter.value }),
      ])
    })

    const stations = computed(() => _.chain(stationModel.items.value)
      .keyBy('name')
      .mapValues('friendlyName')
      .value())

    const procedures = computed(() => _.chain(procedureModel.items.value)
      .keyBy('name')
      .mapValues('friendlyName')
      .value())

    const stationsFilter = computed(() =>
      Object.values(_.pickBy(Object.keys(stations.value), i => {
        return filters.stations[i]
      })),
    )

    const proceduresFilter = computed(() =>
      Object.values(_.pickBy(Object.keys(procedures.value), i => {
        return filters.procedures[i]
      })),
    )

    const filterString = (procedure: string, station: string) => {
      const d = {
        $and: [] as unknown[],
      }

      if (from.value) {
        d.$and.push(
          { 'created.seconds': { $gte: moment(from.value).startOf('D').utc().unix() } },
        )
      }

      if (to.value) {
        d.$and.push(
          { 'created.seconds': { $lte: moment(to.value).endOf('D').utc().unix() } },
        )
      }

      if (procedure) {
        d.$and.push({ procedure })
      }

      if (station) {
        d.$and.push({
          name: {
            $regex: `^${station}/units/[0-9a-fA-F]{8}` +
        '-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$',
          },
        })
      }

      if (d.$and.length === 0) {
        return ''
      }

      return JSON.stringify(d)
    }

    const clear = () => {
      filters.procedures = {}
      filters.stations = {}
      from.value = ''
      to.value = ''
    }

    type Unwrap<T> =
      T extends Promise<infer U> ? U :
      T extends (...args: unknown[]) => Promise<infer U> ? U :
      T extends (...args: unknown[]) => infer U ? U :
      T

    const parseLabel = (l: string): string | number => {
      switch (bin.value) {
        case 'monthly':
          return moment(l, 'YYYY-MM').format('MMMM YYYY')
        case 'weekly':
          return (() => {
            const t = moment(l, 'YYYY-WW')
            const start = t.startOf('W').clone()
            const end = t.endOf('W')
            return start.format('MM/DD') + '-' + end.format('MM/DD')
          })()
        case 'daily':
          return moment(l, 'YYYY-MM-DD').valueOf()
        case 'hourly':
          return moment(l, 'YYYY-MM-DD kk:mm').valueOf()
      }
    }

    const transformResponse = (r: Unwrap<ReturnType<typeof api.analytics.analyticsGetAnalytics>>): Data => {
      switch (bin.value) {
        case 'monthly':
        case 'weekly':
          return _.chain(r.data.data)
            .map(d => ({
              x: parseLabel(d.label as string) as string,
              y: parseFloat(d.value as string),
            }))
            .reverse()
            .value()

        case 'daily':
        case 'hourly':
          return _.chain(r.data.data)
            .map(d => [
              parseLabel(d.label as string) as number,
              parseFloat(d.value as string),
            ] as [number, number])
            .value()
      }
    }

    watch([() => props.bin, () => props.analytic, filters, to, from], debounce(async () => {
      data.value = []
      const d = [] as AnalyticData[]

      if (proceduresFilter.value.length === 0 && stationsFilter.value.length === 0) {
        const filter = filterString('', '')
        d.push({
          name: `${analytics.value[analytic.value]} (All Procedures, All Stations)`,
          data: await api.analytics.analyticsGetAnalytics({
            analytic: analytic.value,
            bin: bin.value,
            filter: filter,
            timezone: moment().format('Z'),
          }).then(
            transformResponse),
        })
        data.value = d
        return
      }

      const selectedProcedures = proceduresFilter.value.length
        ? proceduresFilter.value
        : ['']

      const selectedStations = stationsFilter.value.length
        ? stationsFilter.value
        : ['']

      for (const procedure of selectedProcedures) {
        for (const station of selectedStations) {
          const filter = filterString(procedure, station)
          const stationName = stations.value[station] || 'All Stations'
          const procedureName = procedures.value[procedure] || 'All Procedures'

          d.push({
            name: `${analytics.value[analytic.value]} (${procedureName}, ${stationName})`,
            data: await api.analytics.analyticsGetAnalytics({
              analytic: analytic.value,
              bin: bin.value,
              filter: filter,
              timezone: moment().format('Z'),
            }).then(transformResponse),
            station,
            procedure,
          })
        }
      }

      data.value = d
    }, 250), { immediate: true })

    watch(from, (v) => {
      const f = moment(v)
      const t = moment(to.value)

      if (t.isBefore(f)) {
        to.value = from.value
      }
    })

    watch(to, (v) => {
      const f = moment(from.value)
      const t = moment(v)

      if (t.isBefore(f)) {
        from.value = to.value
      }
    })

    watch(
      () => filters.procedures,
      val => {
        vProcedure.value = Object.keys(val).filter(k => val[k])
      },
      { deep: true },
    )

    watch(
      () => filters.stations,
      val => {
        vStation.value = Object.keys(val).filter(k => val[k])
      },
      { deep: true },
    )

    return {
      filters,
      bins,
      analytics,
      procedureModel,
      stationModel,
      stationsFilter,
      stationOpts: stations,
      procedureOpts: procedures,
      proceduresFilter,
      toModel: to,
      fromModel: from,
      binModel: bin,
      analyticModel: analytic,
      clear,
    }
  },
})
