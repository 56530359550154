
import type { Ref } from 'vue'
import AnalyticsFilter from '@/components/AnalyticsFilters.vue'
import { computed, defineComponent, ref, watch } from 'vue'
import EChart from '@/components/EChart.vue'
import NoData from '@/components/NoData.vue'
import Card from '@/components/Card.vue'
// import moment from 'moment'
import _ from 'lodash'
import moment from 'moment'

type BinValue = 'monthly' | 'weekly' | 'daily' | 'hourly'
type AnalyticValue = 'cycle-time' | 'production-output' | 'deviations'
type AnalyticData = { name: string, data: [number, number][], station?: string, procedure?: string }

export default defineComponent({
  name: 'Analytics',
  components: {
    Card,
    EChart,
    AnalyticsFilter,
    NoData,
  },
  setup() {
    const analytic: Ref<AnalyticValue> = ref('production-output')
    const bin: Ref<BinValue> = ref('weekly')
    const to = ref('')
    const from = ref('')
    const data = ref<AnalyticData[]>([])
    const stations = ref<string[]>([])
    const procedures = ref<string[]>([])

    const durationFmt = (t: number) => {
      const d = t
      const h = ~~(d / 3600)
      const m = ~~(d % 3600 / 60)
      const s = ~~(d % 3600 % 60)

      const hDisplay = h > 0 ? h + 'h ' : ''
      const mDisplay = m > 0 ? m + 'm ' : ''
      const sDisplay = s > 0 ? s + 's ' : ''
      return hDisplay + mDisplay + sDisplay
    }

    // const timeScale = computed(() => {
    //   switch (bin.value) {
    //     case 'hourly':
    //       return 'hour'
    //     case 'daily':
    //       return 'day'
    //     case 'monthly':
    //       return 'month'
    //     case 'weekly':
    //       return 'week'
    //     default:
    //       return 'year'
    //   }
    // })

    const empty = computed(() => _.every(data.value, v => v.data.length === 0))

    // const router = useRouter()
    // eslint-disable-next-line
    // const dataPointSelection = (...[,, c]: [any, any, { seriesIndex: number; dataPointIndex: number }]) => {
    //   const { seriesIndex, dataPointIndex } = c
    //   const label = data.value[seriesIndex].data[dataPointIndex][0]
    //   const from = Math.floor(moment.utc(label).startOf(timeScale.value).valueOf() / 1000)
    //   const to = Math.floor(moment.utc(label).endOf(timeScale.value).valueOf() / 1000)

    //   const point = data.value[seriesIndex]

    //   const q = {
    //     stations: point.station !== undefined && point.station !== '' ? [point.station] : undefined,
    //     procedures: point.procedure !== undefined && point.procedure !== '' ? [point.procedure] : undefined,
    //     deviations: analytic.value === 'deviations' ? 'true' : undefined
    //   }

    //   router.push({
    //     path: '/videos',
    //     query: {
    //       from,
    //       to,
    //       ...q
    //     }
    //   })
    // }
    const x = ref<string[]>([])
    const t = ref<unknown[]>([])
    watch(data, () => {
      t.value.length = 0
      x.value.length = 0

      const s = JSON.parse(JSON.stringify(data.value))
      if (bin.value === 'monthly' || bin.value === 'weekly') {
        for (let i = 0; i < s.length; i++) {
          const d = []
          for (let j = 0; j < s[i].data.length; j++) {
            if (i === 0) {
              const d = s[i].data[j]
              x.value.push(d.x)
            }
            d.push(s[i].data[j].y)
          }
          const pair = {
            name: s[i].name,
            data: d,
            type: 'bar',
          }
          t.value.push(pair)
        }
      } else {
        for (let i = 0; i < s.length; i++) {
          const d = []
          for (let j = 0; j < s[i].data.length; j++) {
            if (i === 0) {
              const d = s[i].data[j]
              const date = moment(d[0])
              x.value.push(date.local().format('MMM-DD'))
            }
            d.push(s[i].data[j][1])
          }
          x.value.reverse()
          d.reverse()
          const pair = {
            name: s[i].name,
            data: d,
            type: 'bar',
          }
          t.value.push(pair)
        }
      }
    })

    const chartOptionsBar = computed(() => ({
      tooltip: {
        height: '300px',
        trigger: 'item',
        position: 'top',
        appendToBody: true,
      },
      title: {
        text: analytic.value.replace('-', ' '),
        style: {
          fontSize: '20px',
        },
      },
      xAxis: {
        type: 'category',
        // type: ['daily', 'hourly'].includes(bin.value) ? 'time' : 'category',
        name: 'Time',
        data: x.value,
        axisTick: {
          alignWithLabel: true,
        },
      },
      yAxis: {
        // name: 'Units',
        name: `${analytic.value === 'cycle-time' ? 'Duration' : 'Units'}`,
        axisLabel: {
          formatter: analytic.value === 'cycle-time'
            ? (v: number) => durationFmt(v)
            : (v: number) => v,
        },
      },
      series: t.value,
      legend: {
        showForSingleSeries: true,
      },
    }))

    const options = computed(() => ({
      chart: {
        foreColor: '#373d3f',
      },
      title: {
        text: analytic.value.replace('-', ' '),
        style: {
          fontSize: '20px',
        },
      },
      xaxis: {
        type: ['daily', 'hourly'].includes(bin.value) ? 'datetime' : 'category',
        title: {
          text: 'Time',
          style: {
            fontSize: '18px',
          },
        },
        labels: {
          showDuplicates: false,
          style: {
            fontSize: '15px',
          },
        },
      },
      yaxis: {
        title: {
          text: `${analytic.value === 'cycle-time' ? 'Duration' : 'Units'}`,
          style: {
            fontSize: '18px',
          },
        },
        decimalsInFloat: 1,
        labels: {
          formatter: analytic.value === 'cycle-time'
            ? (v: number) => durationFmt(v)
            : (v: number) => v,
          style: {
            fontSize: '15px',
          },
        },
      },
      legend: {
        showForSingleSeries: true,
      },
      tooltip: {
        theme: 'dark',
        fixed: {
          enabled: true,
          position: 'topLeft',
          offsetX: 6,
          offsetY: 6,
        },
      },
      style: {
        colors: ['#ff0000'],
      },
      dataLabels: {
        enabled: false,
      },
      subtitle: {
        style: { fontWeight: 'bold' },
      },
    }))

    return {
      analytic,
      bin,
      data,
      to,
      from,
      empty,
      options,
      procedures,
      stations,
      chartOptionsBar,
    }
  },
})
