import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "fancy-select flex" }
const _hoisted_2 = { style: {"width":"100%"} }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "flex-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item, k) => {
        return (_openBlock(), _createElementBlock("button", {
          class: "fancy-select__item flex",
          key: k,
          onClick: ($event: any) => (_ctx.selectionsModel[k] = !_ctx.selectionsModel[k])
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(["icon", [
          _ctx.selectionsModel[k]
            ? 'icon-toggle-on'
            : 'icon-toggle-off'
        ]])
          }, null, 2),
          _createElementVNode("span", _hoisted_4, _toDisplayString(item), 1)
        ], 8, _hoisted_3))
      }), 128))
    ])
  ]))
}