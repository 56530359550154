<template>
  <div class="no-data flex">
    <i class="icon icon-file-circle-xmark no-data__icon" />
    <span>
      No Data!<br />
      Come Back Later
    </span>
  </div>
</template>

<style lang="sass">
@use '@/styles/abstracts' as abs

.no-data
  align-items: center
  justify-content: center
  text-align: center
  margin: auto
  font-weight: abs.$fonts-bold
  color: abs.$colors-gray-1

  &__icon
    height: 3rem
    width: 3rem
    margin-right: 1rem

</style>
